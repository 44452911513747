import { Component, OnInit } from '@angular/core';
import { CommonServiceLayerService } from '../../services/common-service-layer.service';
import { ModalFilterData } from '../shared-filter-modal/filter-modal/filter-modal-interface';
import { SharedService } from '../shared.service';
import { DataTableConfig } from '../table/table-interface';
import { LayoutService } from 'src/app/layout/layout.service';
import { GlobalVariablesService } from 'src/app/layout/global-variables.service';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-shared-exceedence-report',
  templateUrl: './shared-exceedence-report.component.html',
  styleUrls: ['./shared-exceedence-report.component.scss']
})
export class SharedExceedenceReportComponent implements OnInit {

  constructor(
    public modalService: LayoutService,
    private http: CommonServiceLayerService,
    private sharedService: SharedService,
    public globalVariables: GlobalVariablesService) { }
  public showModal = false;
  public modalData: ModalFilterData;
  public filterData = {};
  public tableConfigData: DataTableConfig;
  public responseData;
  public errorStatusMessage;
  public showToggle = false;
  public showFilters = false;
  ngOnInit() {
    this.subscribePopStatus();
    this.getFilterData();
    this.setTableConfig();
  }

  /**
   * Show filter
   */
  openFilter() {
    try {
      this.showModal = true;
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * data table configurations
   */
  setTableConfig() {
    try {
      this.tableConfigData = {
        dtOptions: {
          maxRows: 10,
          searchButton: false,
          columnSearch: false,
          displayOptions: true,
          headerLabel: 'name',
          print: {
            show: false,
            headerContent: { ReportName: 'Exceedance Report' },
            types: ['excel', 'csv', 'pdf'],
            printMetaData: {}
          }
        }
      };
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * Show or hide filter modal
   */
  subscribePopStatus() {
    try {
      this.modalService.getToggleModal().subscribe(response => {
        this.showModal = response;
      });
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * set modal data
   * @param data filters
   */
  setModalData(data) {
    try {
      this.modalData = {
        buttons: [{ title: 'Fetch', actionType: 'fetch', class: 'btn btn-primary' }],
        headerTitle: 'Alarms Report',
        formData: data,
        inputData: this.filterData,
        staticFields: this.globalVariables.FILTER_FORM_MAPPING.staticFields.alarmReport
      };
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * load filter data from API
   */
  getFilterData() {
    const payLoad = {
      loadType: 'alarmReport'
    };
    try {
      this.http.reportsModule.requestHeadersExceedanceReport(payLoad).subscribe(
        response => {
          this.setModalData(response.data.form);
          this.showModal = true;
        });
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * fetch button event
   * @param data to get action type
   */
  modalActionEmitter(data) {
    try {
      if (data.button.actionType === 'fetch') {
        this.showFilters = true;
        //Config.logger(data.payLoad);
        const payLoad = data.payLoad;
        this.responseData = undefined;
        this.http.reportsModule.alarmReportLoader(payLoad).subscribe(response => {
          this.showToggle = true;
          if (response.status) {
            this.responseData = response.data;
            this.modalService.setToggleModal(false);
            if (payLoad.siteId && payLoad.siteName) {
              this.modalService.setSiteData({ key: payLoad.siteId, label: payLoad.siteName });
            }
            this.formatTableData();
          } else {
            this.errorStatusMessage = response.message;
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
      }
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * format table data
   */
  formatTableData() {
    try {
      this.tableConfigData.tableData = this.responseData.tableData;
    } catch (error) {
      Config.logger(error);
    }
  }

}
