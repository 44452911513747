import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsComponent } from './echarts/echarts.component';



@NgModule({
  declarations: [EChartsComponent],
  imports: [
    CommonModule,
    NgxEchartsModule
  ],
  exports: [EChartsComponent]
})
export class EChartsModule { }
